import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

import metaImage from "../images/taylor-stauss.jpg"

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        title
        description
        author
        keywords
      }
    }
  }
`

const SEO = ({
  lang,
  meta,
  title,
  description,
  type,
  image,
  pathname,
  robots,
}) => {
  if (image === undefined) {
    image = metaImage
  }

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const site = { ...data.site.siteMetadata }
        const metaDescription = description || site.description
        const canonical = pathname
          ? pathname === `home`
            ? `${site.siteUrl}`
            : `${site.siteUrl}/${pathname}/`
          : null

        return (
          <Helmet
            htmlAttributes={{ lang }}
            title={title}
            titleTemplate={`%s | ${site.title}`}
            link={canonical ? [{ rel: `canonical`, href: canonical }] : []}
            meta={[
              {
                name: `robots`,
                content: robots || `index, follow`,
              },
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: `keywords`,
                content: site.keywords,
              },
              {
                property: `og:url`,
                content:
                  pathname === `home`
                    ? `${site.siteUrl}`
                    : ` ${site.siteUrl}/${pathname}/`,
              },
              {
                name: `title`,
                property: `og:title`,
                content: title,
              },
              {
                name: `author`,
                content: site.author,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:creator`,
                content: site.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                property: `fb:app_id`,
                content: `${process.env.GATSBY_FACEBOOK_APP_ID}`,
              },
              {
                property: `og:image`,
                content: `${site.siteUrl}${image}`,
              },
              {
                property: `og:image:alt`,
                content: title,
              },
              {
                property: `og:image:width`,
                content: 1200,
              },
              {
                property: `og:image:height`,
                content: 1200,
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
            ].concat(meta)}
          >
            <body className="overflow-x-hidden" />
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  pathname: PropTypes.string,
}

export default SEO
